// UpCircle Color Palette
$light-blue: #3d71b2; 
$dark-blue: #16192c;
$soft-blue: #0c7def69;
$highlight-green:  #b4d7a7;
$highlight-yellow: #E7b34c;
$upcircle-white: #fff;
$soft-gray: rgb(175,175,175);
$soft-background: #F3F2F7;
$component-background: #FDFDFD;
// Border radius should be 12px for all elements. 
$border-radius: 12px;
$icon-size: 32px;
$small-icon: 24px;
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
$default-font: 'Poppins', 'sans-serif';
$form-font-size: 14px;


body{
    background-color: $soft-background;
    font-family: $default-font;
}

.App{
    background-color:$component-background;
}

// Custom functions
@mixin make_unselectable{
    user-select: none;
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer and Edge */
}

// classes for the login and register containers
.formContainer {
    background-color: $dark-blue;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    min-width: 300px;
    // min-height: 800px;

    .formWrapper{
        background-color: white;
        padding: 20px 60px;
        border-radius: $border-radius;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        width: 80vw;
        // max-width: 320px;
        max-width: 300px;
        min-height: 400px;
        @include make_unselectable();
        
        .logo{
            width: 60px;
            margin-bottom: 10px;
            margin-top: 20px;
        }
        .logo_caption{
            font-size: 16px;
            margin-bottom: 10px;
            .brand{
                font-weight: bolder;
                color:$light-blue;
            }


        }
        .title{
            font-size: 14px;
            color: $dark-blue
        }
        form{
            display: flex;
            flex-direction: column;
            gap: 25px;

            input{
                padding: 10px;
                padding-bottom: 5px;
                border: none;
                
                border-bottom: 1px solid $dark-blue;
                width: 250px;
                &::placeholder{
                    color: $soft-gray;
                    font-size: $form-font-size;
                }
            }
            label{
                display:flex;
                align-items: center;
                gap: 10px;
                color: $light-blue;
                font-size: 12px;
                width: 250px;
                cursor: pointer;
                img{
                    width: $icon-size;
                }
            }

            select{
                border: none;
                background-color: $highlight-green;//  #b4d7a7;
                font-size: 12px;
                // margin: 10px;
                padding: 10px;
            }

            button{
                background-color: $dark-blue;
                color: $upcircle-white;
                padding: 10px;
                font-weight: bold;
                border: none;
                cursor: pointer;
                // change color to light blue when hovered.
                &:hover{
                    background-color: $light-blue;
                }
                // change color to highlight yellow when pressed. 
                &:active{
                    background-color: $highlight-yellow;
                }
            }

            .error{
                font-size: 11px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: red;
            }

        }
        p{
            margin-top: 10px;
            font-size: $form-font-size;
            color: $dark-blue;
            align-items: right;
        }
    }
    .upcircleFooter{
        position: absolute;
        bottom: 18px;
        font-size: 12px ;
        @include make_unselectable();
        
        .brand{
            font-weight: bolder;
            color: $light-blue;
        }
        .year{
            margin-left: 5px;
            color: white;
        }
    }

}

.home{
    .Observation {
        float: right;
    }
    
    .Observation img {
        width: 196px;
        margin-bottom: 0.5em;
    }
    
    .Observation .map {
        width: 196px;
        height: 196px;
    }
    
    .Observation p {
        margin-top: 0px;
        margin-bottom: 0.5em;
    }
    
    .Observation-id {
        font-weight: bold;
    }
}

.loading-screen-small{
    // background-color: $dark-blue;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;

    .spinning-logo {
        height: 20vmin;
        pointer-events: none;
    }

    .fullscreen{
        background-color: white;
        height: 100vh;
        width: 100vw;
    }

    @media (prefers-reduced-motion: no-preference) {
        .spinning-logo {
          animation: spinning-logo infinite 10s linear;
        }
    }
      
    @keyframes spinning-logo {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    p{
        color: $light-blue;
    }

}


.loading-screen-fullscreen{
    background-color: $dark-blue;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;

    .spinning-logo {
        height: 20vmin;
        pointer-events: none;
    }

    .fullscreen{
        background-color: white;
        height: 100vh;
        width: 100vw;
    }

    @media (prefers-reduced-motion: no-preference) {
        .spinning-logo {
          animation: spinning-logo infinite 10s linear;
        }
    }
      
    @keyframes spinning-logo {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    p{
        color: $light-blue;
    }

}

