.card{
    display: flex;
    flex-direction: column;
    padding: 10px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 10px;
    height: auto;
    background: #FDFDFD;

    .card_header {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items:center;
      padding: 15px 15px 10px 15px;
      border-bottom: 1px solid #EDECED;

      .iconWrapper{
        grid-column-start: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        .icon {
          font-size: 1.3em;
          padding: 1px;
          border-radius: 5px;
          align-self: flex-end;
        }
      }

      .title {
        grid-column-start: 2;
        justify-content: left;
        margin-right: auto;
        font-weight: bold;
        font-size: 1em;
        color: #464255;
      }

      .timeframe {
        grid-column-start: 2;
        font-size: 0.7em;
        font-weight: medium;
        color: rgb(160, 160, 160);
      }
    }
}

.basiccard-body {
  height: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .counter {
    align-self: center;
    font-size: 3.5em;
    font-weight: bold;
    color: #464255;
  } 
}

.tablecard {
  grid-column: span 3;
}

.tablecard-body {
  display: flex;
  height: 13rem;
}

.table-wrapper {
  height: 100%;
  overflow-y:auto;
  flex: 1;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-family: 'Poppins', 'sans-serif';
  font-weight: medium;
  font-size: 1rem;
}

td, th {
  text-align: left;
  padding: 0.3rem 1rem;
}

thead {
  position: sticky;
  top: 0;
  background-color: #FDFDFD;
}

tr:hover {
  background-color: rgba(10, 134, 249, 0.15);
  cursor: default;
}

.example-image-wrapper {
  padding: 0.5rem;
}

.example-image {
  border-radius: 10px;
  height: 100%;
}

.timelinecard {
  grid-column: span 2;
}

.timelinecard-body {
  height: 13rem;
}

.timeline-chart {
  height: 100%;
  width: 100%;
}