// UpCircle Color Palette
$light-blue: #3d71b2; 
$dark-blue: #16192c;
$soft-blue: #0c7def69;
$highlight-green:  #b4d7a7;
$highlight-yellow: #E7b34c;
$upcircle-white: #fff;
$soft-gray: rgb(175,175,175);
$soft-background: #F3F2F7;

$icon-size: 32px;

.home {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    gap: 2rem;
    height: 100vh; // replaced from view-width vw to view-height vh. 
    // border: 5px solid red;
}

.home--right {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
}

.navbar {
    display: flex;
    flex-direction: column;

    .title-users-logout {
        display: flex;
        justify-content: space-between;

        .title {
            font-weight: semi-bold;
            color: $dark-blue;
            margin: 1rem 0;
        }

        .user{
            display: flex;
            gap: 15px;
            align-items: center;
            color: $dark-blue;
            .user_photo_img{
                background-color: #ddddf7;
                height: $icon-size;
                width: $icon-size;
                border-radius: 50%; //circle
                object-fit: cover;
            }
            label{
                padding-top: 5px; // to align logout menu with photo. 
                cursor: pointer;
                .logout_icon_img{
                    height: $icon-size;
                    margin-right: 15px;
                }
        
            }
            .language-dropdown{
                height: $icon-size;
                border: none;
                background-color: #FDFDFD;
                font: inherit;
                border-radius: 5px;
                padding: 0 0.5rem;
            }
        }

    }

    .timeframe-dropdown {
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position:relative;

        .dropdown-splitbutton {
            height: 3rem;

            .dropdown-splitbutton-text {
                background-color: #FDFDFD;
                border-radius: 10px 0 0 10px;
                padding: 1rem;
                color: rgb(160, 160, 160);
                font-size: 1em;
                border: none;
                height: 100%;
            }
    
            .dropdown-splitbutton-button {
                background-color: #FDFDFD;
                border-radius: 0 10px 10px 0;
                padding: 1rem;
                color: rgb(160, 160, 160);
                text-align: left;
                font-size: 1em;
                font-weight: bold;
                border: none;
                height: 100%;
                min-width: 9rem;
            }
        }
    
        .dropdown-content {
            display: none;
            position: absolute;
            margin-top:3rem;
            background-color: #FDFDFD;
            min-width: 10rem;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            z-index: 1;
            border-radius: 10px;
            text-align: right;
            align-self: flex-end;
            cursor: default;
        }
    
        .dropdown-content a {
            color: rgb(160, 160, 160);
            font-size: 1em;
            font-weight: bold;
            padding: 12px 16px;
            text-decoration: none;
            display: block;
        }
    }

        /* Change color of dropdown links on hover */
    .dropdown-content a:hover {background-color: #d8ebfc}

    /* Show the dropdown menu on hover */
    .timeframe-dropdown:hover .dropdown-content {display: block;}

    /* Change the background color of the dropdown button when the dropdown content is shown */
    .timeframe-dropdown:hover .dropdown-splitbutton-button {background-color: #d8ebfc}
}

.insights {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: min-content;
    gap: 2rem;
}

.sidebar {
    display: flex;
    flex-direction: column;
    padding: 10px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 10px;
    height: auto;
    width: 15rem;
    background: #FCFCFC;
}

.sidebar--logo {
    width: 80%;
    align-self: center;
    margin-top: 2rem;
}

.sidebar--links {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    padding: 0;

    li {
        list-style: none;
        font-size: 1em;
        font-weight: medium;
        color: #464255;
        padding: 0.8rem;
        width: auto;
        margin: 0;
        display: flex;
        align-items: center;
        // justify-content: space-evenly;

        // Add <Link/> styles, represented as "a"
        a {
            text-decoration: none;
            color: #464255;
            display: flex;
            img{
                height: 1.5em;
                width: 1.5em;
                margin-right: 15px;
                
            }
            p{
                margin: 0px;
            }
        }
    }

    li:hover {
        border-radius: 10px;
        background-color: #d8ebfc;
        color: #0C7CEF;
        cursor: default;
    }
}

.insights--top-row-cards {
    display: flex;
    gap: 2rem;
}